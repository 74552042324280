import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { MDBRow, MDBCol, MDBContainer, MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBCarouselCaption } from 'mdbreact'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import ServerBrands from '../components/serverBrands'
import Card from '../components/card'

export default ({ data, location }) => {
    const post = data.markdownRemark
    const brand = data.serverbrands.edges 
    const range = (post.frontmatter.section[0].brand === "E-Series" || 
                    post.frontmatter.section[0].brand === "S-Series" || 
                    post.frontmatter.section[0].brand === "Custom") ? "UEI" : post.frontmatter.section[0].brand
    const resources = data.resources 

    return (
      <Layout>
        <div id="sub-page">
        <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp.fluid.src}
        />

          <Hero
            class="intro-65"
            image={post.frontmatter.image.childImageSharp.fluid}
            foreimage={null}
            imageAltText={post.frontmatter.alttext}
            title={post.frontmatter.title}
            subtitle={post.frontmatter.subtitle}
            ctatext={post.frontmatter.ctatext}
            ctaslug={post.frontmatter.ctaslug}            
            type="root"
          />
        </div>

        {post.frontmatter.section && (
          <section className="bg-white">
            <MDBContainer>
              {post.frontmatter.section.map((sections, index) => {
                return (
                  <>
                    <h3 className="text-center pb-3">{sections.title}</h3>
                    {sections.related && (
                      <MDBRow>
                        <MDBCol className="text-center pb-5">
                          {sections.related.map((button, index) => {
                            return (
                              <Link
                                to={button.link}
                                className="nav-link btn btn-mdb-color mt-4"
                                aria-label="related servers"
                              >
                                {button.linktext}
                              </Link>
                            )
                          })}
                        </MDBCol>
                      </MDBRow>
                    )}
                    <MDBRow>
                      <MDBCarousel
                        activeItem={1}
                        length={post.frontmatter.section[0].feature.length}
                        showControls={true}
                        showIndicators={true}
                        interval={3000}
                        className="no-flex text-center branding"
                      >
                        <MDBCarouselInner>
                          {sections.feature.map((features, index) => {
                            return (
                              <MDBCarouselItem itemId={index + 1} key={index}>
                                <div>
                                  <div className="branding-img-wrapper">
                                    <Img
                                      fluid={
                                        features.image.childImageSharp.fluid
                                      }
                                      className="mb-4 px-3 d-block w-100"
                                      alt={features.alttext}
                                    />
                                    <MDBCarouselCaption>
                                      {features.title && (
                                        <h4 className= "title-small font-w-400 " style={{ whiteSpace: 'pre-wrap' }}>
                                          {features.title}
                                        </h4>
                                      )}
                                    </MDBCarouselCaption>
                                  </div>
                                </div>
                              </MDBCarouselItem>
                            )
                          })}
                        </MDBCarouselInner>
                      </MDBCarousel>
                    </MDBRow>
                    <div className="pt-1 text-center">
                      <Link
                        to="/services/server-branding/"
                        className="nav-link btn btn-mdb-color mt-4"
                        aria-label="server branding"
                      >
                        Return to server branding page
                      </Link>
                    </div>
                  </>
                )
              })}
            </MDBContainer>
          </section>
        )}

        {brand && (
          <ServerBrands
            title={
              range == 'UEI'
                ? 'See more servers by UNICOM Engineering'
                : 'See more from'
            }
            subtitle=""
            brands={brand}
            range={range}
          />
        )}

        {resources && (
          <>
          {resources.frontmatter.section.map((sections, index) => (
          <section className={index % 0 == 0 ? 'bg-light-blue' : 'bg-white'}>
            <MDBContainer>
              <h2 className="font-alt font-w-700 letter-spacing-1 mb-4 pb-4 title-xs-large title-large text-left">
                {sections.title}
              </h2>
              <MDBRow>
                {sections.feature.map((features, index) => (
                    <Card
                      key={index}
                      collg="4"
                      colmd="6"
                      height="5.5rem"
                      title={features.title}
                      subtitle={features.subtitle}
                      description={features.linktext}
                      imageUrl={features.image ? features.image.childImageSharp.fluid : null}
                      link={features.link ? features.link : features.document.publicURL}
                      titleclass="title-small"
                      descriptionClass="text-card-small"
                    />
                  ))}
              </MDBRow>
            </MDBContainer>
          </section>
          ))}
          </>
        )}
      </Layout>
    )
}

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }    
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { template: { eq: "server-branding" } }
    ) {
      fields {
        slug
      }
      frontmatter {
        meta {
          title
          description
        }
        title
        subtitle
        image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alttext
        ctatext
        ctaslug
        section {
          title
          brand
          feature {
            title
            subtitle
            image {
              childImageSharp {
                fluid(maxWidth: 900, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                alttext
            }
          related {
            link 
            linktext
          }
        }
      }
      html
    }
    serverbrands: allMarkdownRemark( filter: {frontmatter: {brandname: {ne: null}}}, sort: {fields: frontmatter___sequence}) {
        edges {
          node {
            frontmatter {
              brandrange
              brandname
              branddescription
              brandlogo {
                childImageSharp {
                  fixed(height: 50, quality: 90) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
              section {
                linktext
                link
              }
            }
          }
        }
      }
    resources: markdownRemark(frontmatter: {template: {eq: "resources"}, title: {eq: "Helpful resources"}}) {
      frontmatter {
        title
        subtitle
        section {
          title
          subtitle
          placement
          feature {
            title
            subtitle
            image {
              childImageSharp {
                fluid(quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            linktext
            link
            document {
              publicURL
            }
          }
        }
      }
    }
  } 
`